import React from 'react'

const MenuImg = () => {
  return (
   <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <g filter="url(#filter0_ddii_21_2170)">
    <rect x="4" y="4" width="32" height="32" rx="10" fill="#334155"/>
    <rect x="4.5" y="4.5" width="31" height="31" rx="9.5" stroke="#7FA0CF"/>
  </g>
  <g filter="url(#filter1_ddii_21_2170)">
    <rect x="10.5" y="10.5" width="7.57143" height="7.57143" rx="1.5" fill="#334155" stroke="#7FA0CF"/>
    <rect x="10.5" y="21.9286" width="7.57143" height="7.57143" rx="1.5" fill="#334155" stroke="#7FA0CF"/>
    <rect x="21.9286" y="10.5" width="7.57143" height="7.57143" rx="1.5" fill="#334155" stroke="#7FA0CF"/>
    <rect x="21.9286" y="21.9286" width="7.57143" height="7.57143" rx="1.5" fill="#334155" stroke="#7FA0CF"/>
  </g>
  <defs>
        <filter id="filter0_ddii_21_2170" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-2" dy="-2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.172549 0 0 0 0 0.223529 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_2170"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-1" dy="-1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_21_2170" result="effect2_dropShadow_21_2170"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_21_2170" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-2" dy="-2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.211765 0 0 0 0 0.27451 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect3_innerShadow_21_2170"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-1" dy="-1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.180392 0 0 0 0 0.235294 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect3_innerShadow_21_2170" result="effect4_innerShadow_21_2170"/>
    </filter>
        <filter id="filter1_ddii_21_2170" x="6" y="6" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-2" dy="-2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.172549 0 0 0 0 0.223529 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_2170"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-1" dy="-1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_21_2170" result="effect2_dropShadow_21_2170"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_21_2170" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-2" dy="-2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.211765 0 0 0 0 0.27451 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect3_innerShadow_21_2170"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-1" dy="-1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.180392 0 0 0 0 0.235294 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect3_innerShadow_21_2170" result="effect4_innerShadow_21_2170"/>
    </filter>
  </defs>
</svg>
  )
}

export default MenuImg
