import React, { useRef, useState } from 'react'
import youtuberacoon from '../images/youtuberaccoon.png';
import emailjs from '@emailjs/browser';
const ContentContributers = () => {
    const form = useRef();
    const [loading, setloading] = useState(0);
    const sendEmail = (e) => {
        e.preventDefault();
        setloading(1)
        emailjs
            .sendForm(
                'service_wl3lswp',
                'template_7w5xgm3',
                form.current,
                'cI5ofVpulitPVu3VE' // This is the correct way to pass the public key
            )
            .then(
                (result) => {
                    // alert('Message sent successfully!');
                    console.log('SUCCESS!', result.text);
                    setloading(2)
                    setTimeout(() => {
                        setloading(0)
                    }, 2000);
                },
                (error) => {
                    // alert('Failed to send the message, please try again.');
                    console.log('FAILED...', error.text);
                    setloading(0)
                }
            );
    };
  return (
      <section className='flex items-center md:flex-row flex-col-reverse md:pt-36 pb-24  gap-16  max-w-screen-lg mx-auto  md:px-8  '>
          <div className='text-primary w-full md:w-full px-8 md:px-2'>
              <h1 className='text-xl md:text-[32px] md:pb-8 pb-4 w-full text-left font-bold'>Contribute to Our Campaigns</h1>
              <form className='w-full' ref={form} onSubmit={sendEmail}>
                  <div>
                      <input placeholder='Name' type="text" id="name" name="name" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                  </div>
                  <div>
                      <input placeholder='Telegram ID' type="text" id="tgid" name="tgid" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                  </div>


                  <div>
                      <select placeholder='views' type="text" id="views" name="views" required className=" bg-secondary w-full p-4 rounded-[25px] border-primary border mt-2 input-field" >
                          <option disabled selected hidden  >views</option>
                          <option>Under 25000</option>
                          <option>25000 - 50000</option>
                          <option>50000 - 100000</option>
                          <option>100000 - 1000000</option>
                      </select>
                  </div>
                  <div>
                      <select placeholder='platform' type="text" id="platform" name="platform" required className=" bg-secondary w-full p-4 rounded-[25px] border-primary border mt-2 input-field" >
                          <option disabled selected hidden  >platform</option>
                          <option>Instagram</option>
                          <option>Twitter</option>
                          <option>Telegram</option>
                          <option>Facebook</option>
                          <option>Youtube</option>
                          <option>TikTok</option>
                          <option>Others</option>
                      </select>
                  </div>
                  <div>
                      <input placeholder='Your Content URL' type="text" id="url" name="url" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                  </div>

                  <div className='flex items-center'>
                      {loading === 0 &&
                          <input type="submit" value="Send" className="submit-button bg-secondary   px-6 py-2 rounded-[25px] border-primary border mt-2 hover:scale-105 duration-300 hover:text-white hover:bg-primary cursor-pointer" />
                      }
                      {(loading === 1 || loading === 2) &&
                          <div className='bg-secondary   px-6 py-2 rounded-[25px] border-primary border mt-2'>
                              {loading === 1 &&
                                  <svg className='animate-spin stroke-primary   rounded-[25px]   ' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 3C16.9706 3 21 7.02944 21 12" stroke-width="2" stroke-linecap="round" />
                                  </svg>
                              }

                              {loading === 2 &&
                                  <svg className='fill-primary' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z" />
                                  </svg>
                              }

                          </div>
                      }
                  </div>
              </form>
          </div>
          <div className='w-10/12'>
              <img
                  className='md:h-fit h-[240px] w-full object-contain '
                  src={youtuberacoon}
                  alt='a full body racon looking at you'
              />
              <p className='text-primary w-full md:pt-4 '>
                  We invite content creators to join us in our marketing system. Share your creativity and unique perspective by providing content for our campaigns. Your work will help us reach new audiences and leave a lasting impact. Join us today and be part of a great project.
              </p>
          </div>

      </section>
  )
}

export default ContentContributers
