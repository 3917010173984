import React from 'react'
import racons from '../images/racons.png'
const About = () => {
  return (
    <section id="about" className='pt-36   flex item-center pb-24 max-w-screen-xl relative mx-auto'>
          <img className='absolute -z-10 -mt-10  w-full animate-pulse h-96 object-cover  md:object-contain ' src={racons} alt='racons' /> 
          <div className='md:w-3/6 w-5/6 mx-auto  text-primary background-about py-12 md:px-12 px-6 '>
              <h2 className='text-center md:text-4xl text-xl  font-bold'>About Racoin</h2>
        <p className=' text-center  md:text-xl text-sm pt-4'>
          Unlock a treasure chest of opportunities for your raccoon family with Racoin. This premier gaming experience is designed to turn your playful instincts into prosperity. Enjoy immersive and rewarding gameplay as you collect virtual coins that can be converted into real-life benefits. Embark on exciting adventures, solve puzzles, and watch your wealth grow along with your raccoon family's fortunes. Join Racoin today and turn your raccoon family's dreams of wealth into a delightful reality.
              </p>
           
      </div>
    </section>
  )
}

export default About
