import React, { useRef, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser';

import ContentContributers from '../components/ContentContributers';
const Form = () => {
    const [drawer, setdrawer] = useState(false);
    const form = useRef();
    const [loading, setloading] = useState(0);
   

  return (
      <div className='mx-auto  relative '>
          <Header drawer={drawer} setdrawer={setdrawer} />
          <div className="drawer  mx-auto">
              <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={drawer} />
              <div className="drawer-content pt-16 md:pt-36   md:h-screen md:w-screen">
               <ContentContributers/>
              </div>
              <div className="drawer-side mt-16 z-30">
                  <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                  <ul className="menu  w-full min-h-full bg-base-100  text-2xl text-shadow text-primary p-6">

                      {/* Sidebar content here */}

                      <li onClick={() => setdrawer(false)}>
                          <a href='/#home' htmlFor="my-drawer" aria-label="close sidebar">Home</a>
                      </li>
                      <li onClick={() => setdrawer(false)}>
                          <a href='/#about' htmlFor="my-drawer" aria-label="close sidebar">About</a>
                      </li>
                      <li onClick={() => setdrawer(false)}>
                          <a href='/#roadmap' htmlFor="my-drawer" aria-label="close sidebar">Roadmap</a>
                      </li>
                      <li onClick={() => setdrawer(false)}>
                          <a href='/#joc' htmlFor="my-drawer" aria-label="close sidebar">Join our community</a>
                      </li>
                      <li className='!bg-transparent !hover:bg-transparent'   >
                          <div className='relative group flex justify-center w-fit' >
                              <span className='group-hover:bg-secondary rounded-full '>Whitepaper</span>
                              <span className='invisible group-hover:visible bg-primary  rounded-b-full px-4 py-1 absolute -z-10 group-hover:translate-y-6 duration-100  text-xs text-white'>Coming Soon</span>

                          </div>

                      </li>


                  </ul>
              </div>
          </div>

          <Footer />

      </div>
  )
}

export default Form
