import React, { useRef, useState } from 'react';
import raconheadphone from '../images/raconheadphone.png';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [loading, setloading] = useState(0);
    const sendEmail = (e) => {
        e.preventDefault();
        setloading(1)
        emailjs
            .sendForm(
                'service_wl3lswp',
                'template_8ider1w',
                form.current,
                'cI5ofVpulitPVu3VE' // This is the correct way to pass the public key
            )
            .then(
                (result) => {
                    // alert('Message sent successfully!');
                    console.log('SUCCESS!', result.text);
                    setloading(2)
                    setTimeout(() => {
                        setloading(0)
                    }, 2000);
                },
                (error) => {
                    // alert('Failed to send the message, please try again.');
                    console.log('FAILED...', error.text);
                    setloading(0)
                }
            );
    };

    return (
        <section className='flex items-center md:flex-row flex-col-reverse pt-24 pb-24 px-2 gap-4  max-w-screen-lg mx-auto'>
            <div className='text-primary w-full md:w-1/2 px-8 md:px-2'>
                <h2 className='text-2xl md:text-[40px] md:pb-8 pb-4 w-full text-left font-bold'>Get in touch</h2>
                <form className='w-full' ref={form} onSubmit={sendEmail}>
                    <div>
                      
                        <input  placeholder='Name' type="text" id="user_name" name="user_name" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                    </div>
                    <div>
                        
                        <input  placeholder='Email' type="email" id="user_email" name="user_email" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                    </div>
                    <div>
                        
                        <input  placeholder='Telegram ID' type="text" id="user_tgid" name="user_tgid" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                    </div>
                    <div>
                        <input  placeholder='Title' type="text" id="title" name="title" required className=" bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                    </div>
                    <div>
                        <select placeholder='label' type="text" id="label" name="label" required className=" bg-secondary w-full p-4 rounded-[25px] border-primary border mt-2 input-field" >
                            <option>General</option>
                            <option>Marketing</option>
                            <option>Listing</option>
                            <option>Partnership</option>
                            <option>Advertisement</option>
                            <option>Others</option>
                        </select>
                    </div>
                    <div>
                       
                        <textarea  placeholder='Message' id="message" name="message" required className="min-h-36 bg-secondary w-full  p-4 rounded-[25px] border-primary border mt-2 input-field" />
                    </div>
                    <div className='flex items-center'>
                        {loading === 0 &&
                        <input type="submit" value="Send" className="submit-button bg-secondary   px-6 py-2 rounded-[25px] border-primary border mt-2 hover:scale-105 duration-300 hover:text-white hover:bg-primary cursor-pointer" />
                        }
                        {(loading === 1 || loading === 2 )&&
                        <div className='bg-secondary   px-6 py-2 rounded-[25px] border-primary border mt-2'>
                            {loading === 1 &&
                                <svg className='animate-spin stroke-primary   rounded-[25px]   ' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 3C16.9706 3 21 7.02944 21 12" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            }
                            
                            {loading === 2 && 
                                <svg className='fill-primary' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z" />
                                </svg>
                            }
                            
                        </div>
                        }
                    </div>
                </form>
            </div>
            <img
                className='md:h-fit h-[240px] object-contain w-1/2'
                src={raconheadphone}
                alt='a full body racon looking at you'
            />
        </section>
    );
};

export default Contact;
