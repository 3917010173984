import React from 'react'

const MenuImgClose = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <g filter="url(#filter0_ddii_21_2644)">
                <rect x="4" y="4" width="32" height="32" rx="10" fill="#334155" />
                <rect x="4.5" y="4.5" width="31" height="31" rx="9.5" stroke="#7FA0CF" />
            </g>
            <g filter="url(#filter1_ddii_21_2644)">
                <path d="M22.8126 19.9983L29.4087 13.4248C29.5951 13.2385 29.743 13.0173 29.8438 12.7739C29.9447 12.5305 29.9966 12.2697 29.9966 12.0062C29.9966 11.7427 29.9447 11.4819 29.8438 11.2385C29.743 10.9951 29.5951 10.7739 29.4087 10.5876C29.2224 10.4013 29.0011 10.2535 28.7576 10.1527C28.5141 10.0519 28.2531 10 27.9896 10C27.726 10 27.465 10.0519 27.2215 10.1527C26.978 10.2535 26.7568 10.4013 26.5704 10.5876L18.575 18.5797C18.3877 18.7654 18.239 18.9864 18.1375 19.2299C18.036 19.4734 17.9838 19.7345 17.9838 19.9983C17.9838 20.2621 18.036 20.5232 18.1375 20.7667C18.239 21.0102 18.3877 21.2312 18.575 21.4169L26.5704 29.409C26.7562 29.5963 26.9773 29.7449 27.2209 29.8463C27.4644 29.9478 27.7257 30 27.9896 30C28.2534 30 28.5147 29.9478 28.7583 29.8463C29.0019 29.7449 29.2229 29.5963 29.4087 29.409C29.5961 29.2232 29.7448 29.0023 29.8463 28.7588C29.9478 28.5153 30 28.2542 30 27.9904C30 27.7266 29.9478 27.4655 29.8463 27.222C29.7448 26.9785 29.5961 26.7575 29.4087 26.5718L22.8126 19.9983ZM11.9988 10.0082C11.4687 10.0082 10.9603 10.2187 10.5854 10.5934C10.2106 10.9681 10 11.4763 10 12.0062V27.9904C10 28.5203 10.2106 29.0285 10.5854 29.4032C10.9603 29.7779 11.4687 29.9884 11.9988 29.9884C12.529 29.9884 13.0374 29.7779 13.4122 29.4032C13.7871 29.0285 13.9977 28.5203 13.9977 27.9904V12.0062C13.9977 11.4763 13.7871 10.9681 13.4122 10.5934C13.0374 10.2187 12.529 10.0082 11.9988 10.0082Z" fill="#7FA0CF" />
            </g>
            <defs>
                <filter id="filter0_ddii_21_2644" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.172549 0 0 0 0 0.223529 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_2644" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_21_2644" result="effect2_dropShadow_21_2644" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_21_2644" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.211765 0 0 0 0 0.27451 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_21_2644" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.180392 0 0 0 0 0.235294 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_21_2644" result="effect4_innerShadow_21_2644" />
                </filter>
                <filter id="filter1_ddii_21_2644" x="6" y="6" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.172549 0 0 0 0 0.223529 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_2644" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_21_2644" result="effect2_dropShadow_21_2644" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_21_2644" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.164706 0 0 0 0 0.211765 0 0 0 0 0.27451 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_21_2644" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.180392 0 0 0 0 0.235294 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_21_2644" result="effect4_innerShadow_21_2644" />
                </filter>
            </defs>
        </svg>
    )
}

export default MenuImgClose
