// src/pages/NotFound.jsx
import React from 'react';

function NotFound() {
    return (
        <div className=' bg-base-300 h-screen w-screen flex justify-center flex-col items-center text-primary'>
            <h1 className='text-2xl font-bold'>404: Page Not Found 🦝</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFound;
