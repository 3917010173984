import React from 'react'
import worker1 from '../images/worker1.png'
import worker2 from '../images/worker2.png'
import worker3 from '../images/worker3.png'
import worker4 from '../images/worker4.png'
import worker5 from '../images/worker5.png'
import worker6 from '../images/worker6.png'

const Roadmap = () => {
  return (
    
    <section id='roadmap' className=' md:px-0 pt-24 pb-24 max-w-screen-lg mx-auto overflow-hidden  '>
      <div className="top-[2000px] n z-10  right-0 h-[100px] w-[100px] md:h-[249px] md:w-[249px] absolute rounded-[1000px] border-[10px] border-solid border-[#7FA0CF] shadow-[0_4px_4px_rgba(127,160,207,0.25)] filter blur-[9.899999618530273px]"></div>
      <div className="top-[1400px] n z-10  left-0 h-[100px] w-[100px] md:h-[149px] md:w-[149px] absolute rounded-[1000px] border-[10px] border-solid border-[#7FA0CF] shadow-[0_4px_4px_rgba(127,160,207,0.25)] filter blur-[9.899999618530273px]"></div>
      
      <div className='relative z-10'>
      <h2 className=' text-2xl md:text-[40px] md:pb-8 pb-4 w-full text-center font-bold text-primary '>Roadmap</h2>
        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-info flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker1} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-info'>Phase 1.1: Concept & Design</h3>
              </div>
            </div>
          </div>
          <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
            <div className='md:w-4/6 w-full border-info  border-l-[16px]    md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
              <ul className='text-info   w-full text-xs md:text-sm pt-4'>
                <li>• Market Research: Analyzing competitors and identify unique selling points (USPs).</li>
                <li>• Concept Development: Defining game mechanics, core features, and reward system.</li>
                <li>• Design Documentation: Creating detailed design documents, including UI/UX, game flow, and economy balance.</li>
              </ul>
            </div>

          </div>
        </div>
      
        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-info flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker2} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-info'>Phase 1.2: Prototype Development</h3>
              </div>
            </div>
          </div>
          <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
            <div className='md:w-4/6 w-full border-info  border-l-[16px]    md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
              <ul className='text-info   w-full text-xs md:text-sm pt-4'>
                <li>• Core Mechanics: Developing the basic tap-to-earn mechanics, including tapping, coin collection, and initial tasks.</li>
                <li>• Basic UI/UX: Designing and implementing the initial user interface and experience.</li>
                <li>• Testing & Feedback</li>
              </ul>
            </div>

          </div>
        </div>

        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-info flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker3} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-info'>Phase 2:  Alpha Version</h3>
              </div>
            </div>
          </div>
          <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
            <div className='md:w-4/6 w-full border-info  border-l-[16px]    md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
              <ul className='text-info   w-full text-xs md:text-sm pt-4'>
                <li>• In-Game Currency: Establishing the Racoin coin and gem system.</li>
                <li>• Social Integration: Add basic social features like friend invites and leaderboards.</li>
                <li>• Additional Features: Implementing additional tasks, puzzles for varied gameplay.</li>
                <li>• Testing and feedback</li>
              </ul>
            </div>

          </div>
        </div>

        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-info flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker4} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-info'>Phase 3: Beta Version</h3>
              </div>
            </div>
          </div>
          <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
            <div className='md:w-4/6 w-full border-transparent  border-l-[16px]    md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
              <ul className='   w-full text-xs md:text-sm pt-4'>
                <li className='text-info'>• Daily Rewards: Implementing a daily check-in feature where players can collect Racoin coins.</li>
                <li className='text-primary'>• Wallet Integration: Activating the connection of Tonkeeper wallet to the Racoin bot.</li>
                <li className='text-primary'>• Daily Combos: Introducing daily combo challenges for players to solve and complete.</li>
                <li className='text-primary'>• Beta Testing</li>

              </ul>
            </div>

          </div>
        </div>
      
        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-transparent flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker6} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-primary'>Phase 4: Gamma Version</h3>
              
              </div>
            </div>
          </div>
            <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
              <div className='md:w-4/6 w-full   border-l-[16px] border-transparent   md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
                <ul className='text-primary   w-full text-xs md:text-sm pt-4'>
                <li>• Content Expansion: Introducing new levels, tasks, and adventure modes.</li>
                <li>• Gem Utilization: Introducing initial uses for Racoin gems (e.g., unlocking special items, buying NFT).</li>
                <li>• Gamma Testing</li>
                </ul>
              </div>
          
          </div>
        </div>


        <div tabIndex={0} className="collapse w-full mx-auto px-4  py-2 !static">
          {/* <input type="checkbox" /> */}
          <div className="collapse-title text-xl font-medium p-0">
            <div className='md:w-4/6 border-l-[16px] border-transparent flex md:rounded-[100px] rounded-[60px] md:p-6 p-4 boxinside-roadmap items-center gap-4 bg-base-100 mx-auto mt-4'>
              <img className='w-20 h-20 md:w-[90px] md:h-[90px]' src={worker5} alt="worker" />
              <div className='flex justify-between w-full items-center '>
                <h3 className='md:text-3xl text-xl font-bold text-primary'>Phase 5: Distribution</h3>
              </div>
            </div>
          </div>
          <div className="collapse-content w-full md:px-24 px-8 md:-mt-4 -mt-2">
            <div className='md:w-4/6 w-full   border-l-[16px] border-transparent   md:rounded-[100px]  md:p-6 p-4 boxinside-roadmap items-center  bg-base-100 mx-auto !rounded-[10px] '>
              <ul className='text-primary   w-full text-xs md:text-sm pt-4'>
                <li>• Monetization Opportunities: players can withdraw Toncoins earned from inviting friends.</li>
                <li>• Final Polishing: Optimizing the game for performance, bug fixes, and final balancing.</li>
                <li>• Exchange Listing: Launching the Racoin on reputable exchanges, allowing players to withdraw and sell their Racoin coins.</li>
              </ul>
            </div>

          </div>
        </div>
       

        </div>
        
      </section>
  
  )
}

export default Roadmap
