import React from 'react'
import gecko from '../images/logos/gecko.png'
import marketcap from '../images/logos/marketcap.png'
import tonblockchain from '../images/logos/tonblockchain.png'
import topkeeper from '../images/logos/tonkeeper.png'
import notcoin from '../images/logos/notcoin.png'
const Partners = () => {
    return (
        <section className='pt-24 pb-24'>
            <h2 className=' text-2xl md:text-[40px] md:pb-8 pb-2 w-full text-center font-bold text-info'>Our Partners</h2>

            <div className='logos '>
                <div className="logos-slide !flex">
                    <img alt="gecko" src={gecko} />
                    <img alt="marketcap" src={marketcap} />
                    <img alt="tonblockchain" src={tonblockchain} />
                    <img alt="topkeeper" src={topkeeper} />
                    <img alt="notcoin" src={notcoin} />

                    <img  alt="gecko" src={gecko} />
                    <img  alt="marketcap" src={marketcap} />
                    <img  alt="tonblockchain" src={tonblockchain} />
                    <img  alt="topkeeper" src={topkeeper} />
                    <img  alt="notcoin" src={notcoin} />

                    <img alt="gecko" src={gecko} />
                    <img alt="marketcap" src={marketcap} />
                    <img alt="tonblockchain" src={tonblockchain} />
                    <img alt="topkeeper" src={topkeeper} />
                    <img alt="notcoin" src={notcoin} />
                </div>


            </div>
        </section>
    )
}

export default Partners
