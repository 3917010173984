import React from 'react'
import fullbodyracon from '../../src/images/fullbodyracon.png'
const JOC = () => {
  return (
    <section id='joc' className='flex items-center md:flex-row flex-col pt-24 pb-24 px-2  max-w-screen-lg mx-auto'>
      <img className='  md:h-[453px] h-[240px] object-contain  w-1/2' src={fullbodyracon} alt='a full body racon looking at u' />
      <div className='text-primary md:w-1/2 px-8  md:px-2'>
        <h2 className=' text-2xl md:text-[40px] md:pb-8 pb-4 w-full text-center font-bold'>JOIN OUR COMMUNITY</h2>
        <p className='text-lg md:text-xl text-center'>Join  the large raccoon community with Racoin and be a part of its dynamic and active ecosystem. Discover endless opportunities and connect with like-minded individuals to collaborate, grow, and thrive together!</p>
        <div className='flex justify-center '>
          <a href='https://t.me/Racoin_community' className=' bg-main md:py-3 md:px-10 px-6 py-2 mt-4 animate-bounce md:text-2xl text-xl border border-primary rounded-[25px] !bg-secondary hover:!bg-primary hover:text-white duration-200 ping shadow-[0px_4px_8.3px_1px_rgba(127,160,207,0.25)] '>Join</a>
        </div>
      </div>

    </section>
  )
}

export default JOC
