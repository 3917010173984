import React, { useEffect, useState } from 'react'
import logo from '../images/logos/ourlogo.png'
const Loading = () => {
    const [loading, setloading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setloading(false)
        }, 2500);
    }, []);

    if (!loading) return (<>
        <div className=' absolute  w-full -top-10 overflow-hidden  h-10 bg-[rgba(127,160,207,0.5)] filter blur-[9.449999809265137px] drop-shadow-[0_4px_4px_rgba(127,160,207,0.25)]'></div>
    </>)
  return (
    <div className='fixed bg-base-100 w-screen h-screen z-50 flex items-center justify-center'>
          <div className="flex-container">
              <img className='mx-auto' width={100} src={logo} alt='loading raccon' />
              <p className="box-progress text-center text-4xl font-bold " data-text="Racoin">
                  Racoin
              </p>
          </div>
    </div>
  )
}

export default Loading
