import React from 'react'
import logo from '../images/logo.png'
import MenuImg from '../images/MenuImg'
import MenuImgClose from '../images/MenuImgClose'

const Header = ({ drawer, setdrawer }) => {

    return (
        <div className='mx-auto w-screen flex justify-center  '>
            <header className='fixed w-full max-w-screen-xl mx-auto z-30 background-blur px-4 '>
            <div className='flex items-center justify-between p-4 duration-300 md:hidden'>
                <label onClick={() => setdrawer(!drawer)} htmlFor="my-drawer" className="drawer-button cursor-pointer duration-300"> {drawer ? <MenuImgClose /> : <MenuImg />}</label>
                <p className='text-4xl text-primary text-shadow font-bold'>Racoin</p>
                <img className=' rounded-[10px] w-8 h-8' alt="logo" src={logo} />
            </div>
            <div className=' items-center justify-between p-4 duration-300 hidden md:flex  '>
                <div className='flex items-center gap-4 w-1/3'>
                <img className='rounded-[10px]' alt="logo" src={logo} />
                    <p className='text-4xl text-primary text-shadow font-bold'>Racoin</p>
                </div>
                <ul className='flex gap-4 text-primary text-lg w-2/3 justify-end'>
                <li className='hover:translate-y-1 duration-300'>
                    <a href='/' htmlFor="my-drawer" aria-label="close sidebar">Home</a>
                </li>
                <li className='hover:translate-y-1 duration-300'>
                    <a href='/#about' htmlFor="my-drawer" aria-label="close sidebar">About</a>
                </li>
                <li className='hover:translate-y-1 duration-300'>
                    <a href='/#roadmap' htmlFor="my-drawer" aria-label="close sidebar">Roadmap</a>
                </li>
                <li className='hover:translate-y-1 duration-300'>
                    <a href='/#joc' htmlFor="my-drawer" aria-label="close sidebar">Join our community</a>
                </li>
                <li className='!bg-transparent !hover:bg-transparent'   >
                    <div className='relative group flex justify-center w-fit' >
                        <span className='group-hover:bg-secondary rounded-full px-4 '>Whitepaper</span>
                        <span className='invisible group-hover:visible bg-primary  rounded-b-full px-4 py-1 absolute -z-10 group-hover:translate-y-6 duration-100  text-center text-xs text-white'>Coming Soon</span>
                    </div>
                </li>
                </ul>
            </div>
            </header>
        </div>
    )
}

export default Header
