import React from 'react'
import heroimg from '../images/heroimg.png'
const Hero = () => {
  return (
    <section id="home" className='flex md:flex-row flex-col px-4 max-w-screen-lg mx-auto'>
      <div className="top-[500px] n -z-10  right-0 h-[100px] w-[100px] md:h-[249px] md:w-[249px] absolute rounded-[1000px] border-[10px] border-solid border-[#7FA0CF] shadow-[0_4px_4px_rgba(127,160,207,0.25)] filter blur-[9.899999618530273px]"></div>
          <div className=' md:w-1/2'>
              <img className='object-contain md:w-fit w-full mx-auto' src={heroimg} alt='heroimg' />
            <div className='-mt-5 text-primary text-center  text-shadow  md:text-[16px] text-[12px] pb-4 w-full px-2 mx-auto' >
                    Racoin is a Play to earn game on Ton Blockchain  that offers players the opportunity to mine coins by tapping their and doing tasks.
              </div>
      </div>
          <div className='flex flex-col gap-4 text-center items-center text-primary  md:w-1/2 justify-center'>
        <h1 className='md:text-3xl text-2xl text-shadow  w-full md:pb-10 font-bold'>Elevate Your Family to <br />
          Royalty with Racoin!</h1>
        <a href='https://t.me/Racoin_appbot' className=' bg-main md:py-6 md:px-8 px-6 py-4  md:text-4xl text-xl border border-gold text-gold rounded-[25px] !bg-secondary hover:!bg-gold hover:text-white duration-200 ping shadow-[0px_4px_8.3px_1px_rgba(255,215,0,0.25)] animate-bounce'>Play Game</a>
              <a href='#about' className='md:py-6 md:px-8 px-6 py-4 mt-4 md:text-2xl border border-primary rounded-[25px] text-primary hover:bg-primary hover:text-white duration-200 '>Read More</a>
      </div>
    </section>
  )
}

export default Hero
